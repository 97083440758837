<template>
  <el-drawer
    title="移动"
    :close-on-click-modal="false"
    :visible.sync="show"
    append-to-body
    :before-close="handleClose"
    width="550px"
  >
    <el-form label-width="80px">
      <el-form-item label="移动字段">
        <el-cascader
          :show-all-levels="false"
          ref="moveTree"
          style="width: 100%"
          :options="detailData"
          :props="{
            label: 'fieldName',
            value: 'id',
            emitPath: false,
            multiple: true,
          }"
          v-model="idFrom"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="移动到">
        <el-cascader
          style="width: 100%"
          :options="tmpDetailData"
          :props="{
            label: 'fieldName',
            value: 'id',
            checkStrictly: true,
          }"
          clearable
          v-model="idTo"
        ></el-cascader>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="closeMoveModel">取 消</el-button>
      <el-button type="primary" @click="handleMoveSubmit">确定</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { moveField } from '@/api/field'
export default {
  name: 'edit',
  props: {
    show: Boolean,
    detailData: Array
  },
  watch: {
    show: function (value) {
      if (value) {
        this.tmpDetailData = JSON.parse(JSON.stringify(this.detailData))
        this.tmpDetailData.unshift({
          fieldCode: 'top',
          fieldName: '顶层',
          id: 0
        })
      }
    }
  },
  data () {
    return {
      idFrom: [],
      idTo: [],
      permissionId: [],
      tmpDetailData: []
    }
  },
  methods: {
    closeMoveModel () {
      this.$emit('close')
    },
    handleMoveSubmit () {
      if (this.idFrom.length > 0 && this.idTo.length > 0) {
        moveField(this.idFrom.join(','), { parentFieldId: this.idTo[0] })
          .then((res) => {
            this.$message.success('移动成功')
            this.$emit('refresh')
            this.$emit('close')
          })
          .catch(() => {})
      } else {
        this.$message.warn('选项不能为空')
      }
    },
    handleClose () {
      this.sendList = []
      this.$emit('close')
    }
  },
  created () {}
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>
