<template>
  <el-container class='field-container'>
    <el-main class="main-cont border-radius-6 box-shadow-light-grey" >
      <div class="field-header padding-bottom-20" >
        <div class="header-left">
          <el-button
            class="new-field "
            @click="handlePageChange(1)"
            style="margin-bottom: 15px"
          >刷新数据</el-button>
          <el-button
            class="new-field"
            type="primary"
            @click="handleAddGroup"
            style="margin-bottom: 15px"
            >新增字段组</el-button
          >
        </div>
        <div class="header-right">
          <el-input
            style='width:300px'
            placeholder="请输入数据类型/类型名称"
            clearable
            v-model="search"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <el-table
        v-loading="loading"
        :data="GroupList"

        height="calc(100vh - 262px)"
        style="width: 100%;"
      >
        <el-table-column prop="id" label="字段组id" width="100">
        </el-table-column>
        <el-table-column prop="typeName" label="类型名称"> </el-table-column>
        <el-table-column prop="dataType" label="数据类型"> </el-table-column>
        <el-table-column prop="img" label="图片">
          <template slot-scope="scope">
            <img
              class="img-shrink"
              :src="scope.row.img"
              alt=""
              style="width: 50px; height: 50px"
              @mouseenter="showImgDetail($event, scope.row.img)"
              @mouseleave="hideImgDetail()"
            />
          </template>
        </el-table-column>
        <el-table-column prop="graph" label="是否为图形统计" v-if="userRight">
          <template slot-scope="scope">
            {{ scope.row.graph ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button class='button-small' size="mini" @click="handleDetail(scope.row)"
              >详情</el-button
            >
            <el-button
            class='button-small'
              type='primary'
              size="mini"
              @click="handleEdit(scope.row)"
              v-if="userRight"
              >编辑</el-button
            >
            <el-button
            class='button-small'
              size="mini"
              type="danger"
              v-if="userRight"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <img id="imgDetail" v-show="bigImg.isShow" :src="bigImg.src" alt="" />
    </el-main>
    <edit-field-group
      :show="isEdit"
      @close="isEdit = false"
      :fieldData="current"
      @refresh="handlePageChange"
    ></edit-field-group>
    <current-detail
      v-if="showDetail"
      :ralationId="fieldId"
      :fieldData="current"
      :show="showDetail"
      @close="showDetail = false"
    ></current-detail>
  </el-container>
</template>

<script>
import { getRelationGroup, delFieldGroup } from '@/api/field'
import { mapGetters } from 'vuex'
import CurrentDetail from './detail'
import EditFieldGroup from './edit'
export default {
  name: 'index',
  data () {
    return {
      GroupList: [],
      loading: false,
      bigImg: {
        src: '',
        isShow: false
      },
      showDetail: false,
      current: null,
      isEdit: false,
      fieldId: null,
      searchDataType: null,
      searchTypeName: null,
      search: ''
    }
  },
  filters: {
    widthRatio: function (value) {
      switch (value) {
        case 6:
          return '1/4'
        case 12:
          return '1/2'
        case 18:
          return '3/4'
        case 24:
          return '全屏'
        default:
          break
      }
    },
    heightRatio: function (value) {
      switch (value) {
        case 726:
          return '全屏'
        case 318:
          return '1/2'
        case 182:
          return '1/3'
        default:
          break
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    userRight: function () {
      return this.userInfo.id === 1
    },
    switchChange: function (val) {
      return val === 1
    }
  },
  methods: {
    handleEdit (row) {
      this.current = row
      this.isEdit = true
    },
    handleDelete (row) {
      this.$confirm('此操作将永久删除该描述， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delFieldGroup(row.id, {
            typeName: row.typeName
          }).then(res => {
            this.$message.success('删除成功')
            this.handlePageChange()
          })
        })
        .catch(() => {})
    },
    handleDetail (row) {
      this.fieldId = row.id
      this.current = row
      this.showDetail = true
    },
    // 图片放大到原图大小
    showImgDetail (event, src) {
      this.bigImg.src = src
      this.bigImg.isShow = true
      const img = document.getElementById('imgDetail')
      // const targetX = 20 + event.clientX - img.offsetWidth / 2;
      // const targetY = 20 + event.clientY - img.offsetHeight / 2;
      const targetX = 50 + event.clientX
      const targetY = document.body.clientHeight / 2 - 100
      img.style.left = targetX + 'px'
      img.style.top = targetY + 'px'
    },
    hideImgDetail () {
      this.bigImg.isShow = false
    },
    handlePageChange (page) {
      this.init()
    },
    handleAddGroup () {
      this.current = null
      this.isEdit = true
    },
    handleSearch () {
      this.loading = true
      getRelationGroup({
        search: this.search
      })
        .then(res => {
          this.GroupList = res
        })
        .finally(() => (this.loading = false))
    },
    init () {
      this.loading = true
      getRelationGroup()
        .then(res => {
          this.GroupList = res
        })
        .finally(() => (this.loading = false))
    }
  },
  components: {
    CurrentDetail,
    EditFieldGroup
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.field-container{
  // height:calc(100vh - 176px);
}
.el-header {
  background-color: #fff;
}
.field-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  .el-button.new-field {
    margin-bottom: 0 !important;
  }
}
.header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  .el-input {
    padding-right: 10px;
  }
}
.el-main {
  padding: 0px;
  overflow: hidden;
}
.el-main.main-cont {
  box-sizing: border-box;
  padding:20px;
  background: #fff;
}
.el-table__body-wrapper {
  height: 100%;
}
#imgDetail {
  position: absolute;
  z-index: 999999;
  max-width: 500px;
  left: 0;
  top: 0;
}
</style>
