<template>
  <el-drawer
    :title="`${fieldData.fieldName ? '编辑' : '新增'}字段`"
    :visible.sync="show"
    width="550px"
    append-to-body
    :before-close="handleClose"
  >
    <el-form label-width="120px">
      <el-form-item label="字段名称">
        <el-input v-model="form.fieldName" placeholder="请输入字段名称" />
      </el-form-item>
      <el-form-item label="字段code">
        <el-input v-model="form.fieldCode" placeholder="请输入字段code" />
      </el-form-item>
      <el-form-item label='字段对齐方式'>
        <el-radio-group v-model="form.fontAlign" >
          <el-radio :label='0'>居左</el-radio>
          <el-radio :label='1'>居中</el-radio>
          <el-radio :label='2'>居右</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否为动态字段">
        <!-- <el-switch
          :active-value="1"
          :inactive-value="0"
          v-model="form.autoFields"
        >
        </el-switch> -->
        <el-select v-model="form.autoFields" placeholder="字段类型选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="字体颜色">
        <el-color-picker v-model="form.fontColor" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>
      <el-form-item label="表格宽度">
        <el-input v-model="form.width" placeholder="请输入表格宽度" />
      </el-form-item>
    </el-form>
    <div class="drawer-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { addRelation, changeField } from '@/api/field.js'
export default {
  name: 'edit',
  props: {
    show: Boolean,
    ralationId: Number,
    fieldData: Object
  },

  data () {
    return {
      form: {},
      options: [
        {
          value: 0,
          label: '无'
        },
        {
          value: 1,
          label: '动态列表'
        },
        {
          value: 2,
          label: '静态排序'
        },
        {
          value: 3,
          label: '动态排序'
        },
        {
          value: 4,
          label: '静态排序并分隔'
        },
        {
          value: 5,
          label: '动态排序并分隔'
        }
      ],
      predefineColors: [
        '#272727',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ]
    }
  },
  watch: {
    show () {
      this.form.fieldGroupId = this.ralationId
      this.form.parent_id = 0
      if ('token' in this.fieldData && this.fieldData.token === 'edit') {
        this.form.fieldCode = this.fieldData.fieldCode
        this.form.fieldName = this.fieldData.fieldName
        this.form.autoFields = this.fieldData.autoFields
        this.form.fontSize = this.fieldData.fontSize
        this.form.fontColor = this.fieldData.fontColor
        this.form.width = this.fieldData.width
        this.form.fontAlign = this.fieldData.fontAlign
      } else {
        this.form.fieldCode = null
        this.form.fieldName = null
        this.form.autoFields = 0
        this.form.fontSize = 12
        this.form.fontColor = '#272727'
        this.form.width = 90
        this.form.fontAlign = 0
      }
      this.form = JSON.parse(JSON.stringify(this.form))
    },
    fieldData () {
      if ('token' in this.fieldData && this.fieldData.token === 'edit') {
        this.form.fieldCode = this.fieldData.fieldCode
        this.form.fieldName = this.fieldData.fieldName
        this.form.autoFields = this.fieldData.autoFields
        this.form.fontSize = this.fieldData.fontSize
        this.form.fontColor = this.fieldData.fontColor
        this.form.width = this.fieldData.width
      } else {
        this.form.fieldCode = null
        this.form.fieldName = null
        this.form.autoFields = 0
        this.form.fontSize = 12
        this.form.fontColor = '#272727'
        this.form.width = 90
      }
      this.form = JSON.parse(JSON.stringify(this.form))
    }
  },
  methods: {
    handleSubmit () {
      if (this.checkForm() === true) {
        if (this.fieldData.token === 'edit') {
          // 是否是编辑
          changeField(this.fieldData.id, {
            fieldCode: this.form.fieldCode,
            fieldName: this.form.fieldName,
            autoFields: this.form.autoFields,
            fontSize: this.form.fontSize,
            fontColor: this.form.fontColor,
            width: this.form.width,
            fontAlign: this.form.fontAlign
          }).then((res) => {
            this.$message.success('保存成功')
            this.handleClose()
            this.$emit('refresh')
          })
        } else if (this.fieldData.token === 'add') {
          // 是否是新增
          addRelation({
            fieldCode: this.form.fieldCode,
            fieldName: this.form.fieldName,
            fieldGroupId: this.form.fieldGroupId,
            parentId: this.fieldData.id,
            autoFields: this.form.autoFields,
            fontSize: this.form.fontSize,
            fontColor: this.form.fontColor,
            width: this.form.width,
            fontAlign: this.form.fontAlign
          }).then((res) => {
            this.$message.success('保存成功')
            this.handleClose()
            this.$emit('refresh')
          })
        } else {
          addRelation({
            fieldCode: this.form.fieldCode,
            fieldName: this.form.fieldName,
            fieldGroupId: this.form.fieldGroupId,
            parentId: this.form.parent_id,
            autoFields: this.form.autoFields,
            fontSize: this.form.fontSize,
            fontColor: this.form.fontColor,
            width: this.form.width,
            fontAlign: this.form.fontAlign
          }).then((res) => {
            this.$message.success('保存成功')
            this.handleClose()
            this.$emit('refresh')
          })
        }
      } else {
        this.$message.warning('请输入' + this.checkForm())
      }
    },
    handleClose () {
      this.form = {}
      this.switchStatu = false
      this.$emit('close')
    },
    handleSwitch () {
      this.form.graph = this.switchChange
    },
    checkForm () {
      if (!this.form.fieldCode) {
        return '字段code'
      } else if (!this.form.fieldGroupId) {
        return '字段组id'
      }
      return true
    }
  },
  created () {
    this.form.fieldGroupId = this.ralationId
    this.form.parent_id = 0
  }
}
</script>

<style scoped>
.tips {
  font-size: 12px;
  color: #999;
}
.drawer-footer {
  margin: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
