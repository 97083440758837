<template>
  <el-drawer
    :title="`${fieldData.typeName}：${fieldData.dataType} 字段详情`"
    center
    append-to-body
    :visible.sync="show"
    top="5vh"
    :size="userRight ? '1400px' : '800px'"
    :close-on-click-modal="false"
    :before-close="() => $emit('close')"
    style="border-radius: 5px"
  >
    <el-header height="45px">
      <div class="detail-header">
        <div class="header-left">
          <el-button
            class="new-book"
            @click="handlePageChange(1)"
            style="margin-bottom: 15px"
            >刷新数据</el-button
          >
          <el-button
            class="new-book"
            @click="handleSortField"
            style="margin-bottom: 15px"
            >排序</el-button
          >
          <el-button
            class="new-book"
            @click="handleMerger"
            v-if="userRight"
            style="margin-bottom: 15px"
            >移动</el-button
          >
          <el-button
            class="new-book"
            type="primary"
            @click="handleAddField"
            v-if="userRight"
            style="margin-bottom: 15px"
            >新增字段</el-button
          >
        </div>
        <div class="header-right" style="margin-top: -15px">
          <el-select
            size="medium"
            v-model="searchData"
            filterable
            style="width: 300px; margin-left: 20px"
            placeholder="请输入字段名"
            clearable
            @change="selectChange"
            @clear="init()"
          >
            <el-option
              v-for="item in detailData"
              :key="item.id"
              :label="item.fieldName"
              :value="item.fieldName"
            >
              <span>{{ item.fieldName + "：" + item.fieldCode }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </el-header>
    <el-table :data="detailData" height="80vh">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.children" style="margin-left: 47px">
            <el-table-column prop="id" width="80px" align="center">
            </el-table-column>
            <el-table-column prop="fieldName" width="150px"> </el-table-column>
            <el-table-column prop="fieldCode" width="200px"> </el-table-column>
            <el-table-column property="show" width="120px">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.show"
                  :active-value="1"
                  :inactive-value="0"
                  @change="handleSwitch(scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              property="autoFields"
              label="动态字段"
              width="120px"
            >
              <template slot-scope="scope">
                <!-- <el-switch
                  disabled
                  v-model="scope.row.autoFields"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch> -->
                <span>{{ scope.row.autoFields | filterAutofields }}</span>
              </template>
            </el-table-column>
            <el-table-column label="字体对齐方式" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.fontAlign | filterAlign }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="graph" width="100">
              <template slot-scope="scope">
                <p
                  class="feild-color"
                  :style="'background-color:' + scope.row.fontColor"
                ></p>
              </template>
            </el-table-column>
            <el-table-column prop="width" width="50">
              <template slot-scope="scope">
                {{ scope.row.width }}
              </template>
            </el-table-column>
            <el-table-column label="操作" v-if="userRight">
              <template slot-scope="scope">
                <el-button
                  class="button-small"
                  size="mini"
                  @click="handleChilrenEdit(scope.row)"
                  v-if="userRight"
                  >编辑</el-button
                >
                <el-button
                  class="button-small"
                  size="mini"
                  @click="handleGetUsers(scope.row)"
                  >查询用户</el-button
                >
                <el-button
                  class="button-small"
                  size="mini"
                  type="danger"
                  v-if="userRight"
                  @click="handleChilrenDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        property="id"
        width="80px"
        label="字段id"
      ></el-table-column>
      <el-table-column
        property="fieldName"
        width="150px"
        label="字段名"
      ></el-table-column>
      <el-table-column
        property="fieldCode"
        width="200px"
        label="字段code"
      ></el-table-column>
      <el-table-column property="show" label="入职人员是否展示" width="120px">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.show"
            :active-value="1"
            :inactive-value="0"
            @change="handleSwitch(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column property="autoFields" label="字段类型" width="120px">
        <template slot-scope="scope">
          <span>{{ scope.row.autoFields | filterAutofields }}</span>
        </template>
      </el-table-column>
      <el-table-column label="字体对齐方式" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.fontAlign | filterAlign }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="graph" label="字体颜色" width="100">
        <template slot-scope="scope">
          <p
            class="feild-color"
            :style="'background-color:' + scope.row.fontColor"
          ></p>
        </template>
      </el-table-column>
      <el-table-column prop="width" label="表格宽度" width="50">
        <template slot-scope="scope">
          {{ scope.row.width }}
        </template>
      </el-table-column>
      <el-table-column label="操作" v-if="userRight">
        <template slot-scope="scope">
          <el-button
            class="new-book button-small"
            @click="handleSortField(scope.row)"
            >排序</el-button
          >
          <el-button
            class="button-small"
            size="mini"
            @click="handleAdd(scope.row)"
            >新增</el-button
          >
          <el-button
            class="button-small"
            size="mini"
            @click="handleEdit(scope.row)"
            v-if="userRight"
            >编辑</el-button
          >
          <el-button
            class="button-small"
            size="mini"
            @click="handleGetUsers(scope.row)"
            >查询用户</el-button
          >
          <el-button
            class="button-small"
            size="mini"
            type="danger"
            v-if="userRight"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <edit-feild
      :show="isEdit"
      :ralationId="ralationId"
      :fieldData="rowFieldData"
      @close="isEdit = false"
      @refresh="handlePageChange"
    ></edit-feild>
    <sort
      :show="isSort"
      @close="isSort = false"
      :sortList="toSortData"
      @sortChange="handleSortNode"
      :labelProps="['fieldName', 'fieldCode']"
    ></sort>
    <users
      :show="isUsers"
      :fieldId="toUserData"
      @close="isUsers = false"
    ></users>
    <merger
      :detailData="detailData"
      @close="isMerger = false"
      @refresh="handlePageChange"
      :show="isMerger"
    ></merger>
  </el-drawer>
</template>

<script>
import {
  getRelationDetail,
  delField,
  changeEntryShow,
  sortField
} from '@/api/field'
import Sort from '@/components/Sort'
import Users from './users'
import EditFeild from './editFeild'
import Merger from './merger'
import { mapGetters } from 'vuex'
export default {
  name: 'detail',
  props: {
    show: Boolean,
    ralationId: Number,
    fieldData: Object
  },
  data () {
    return {
      detailData: [],
      loading: false,
      isEdit: false,
      editForm: {},
      rowFieldData: {},
      switchChange: false,
      isSort: false,
      sortList: null,
      searchData: null,
      toSortData: null,
      isUsers: false,
      toUserData: null,
      isMerger: false
    }
  },
  filters: {
    filterAutofields: function (value) {
      switch (value) {
        case 0:
          return '无'
        case 1:
          return '动态列表'
        case 2:
          return '静态排序'
        case 3:
          return '动态排序'
        case 4:
          return '静态排序并分隔'
        case 5:
          return '动态排序并分隔'
        default:
          break
      }
    },
    filterAlign (val) {
      switch (val) {
        case 0:
          return '居左'
        case 1:
          return '居中'
        case 2:
          return '居右'
      }
    }
  },
  methods: {
    handleMerger () {
      this.isMerger = true
    },
    handleAddField () {
      this.rowFieldData = {}
      this.isEdit = true
    },
    handleSwitch (e) {
      changeEntryShow(e.id, {
        show: e.show
      }).then((res) => {
        this.$message.success('修改成功')
      })
    },
    handleAdd (e) {
      this.rowFieldData = e
      this.rowFieldData.token = 'add'
      this.isEdit = true
    },
    handleEdit (e) {
      this.rowFieldData = e
      this.rowFieldData.token = 'edit'
      this.isEdit = true
    },
    handleDelete (e) {
      this.$confirm('此操作将永久删除该描述， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delField(e.id).then((res) => {
            this.$message.success('删除成功')
            this.handlePageChange()
          })
        })
        .catch(() => {})
    },
    handleChilrenEdit (e) {
      this.rowFieldData = e
      this.rowFieldData.token = 'edit'
      this.isEdit = true
    },
    handleChilrenDelete (e) {
      this.$confirm('此操作将永久删除该描述， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delField(e.id).then((res) => {
            this.$message.success('删除成功')
            this.handlePageChange()
          })
        })
        .catch(() => {})
    },
    handlePageChange () {
      this.init()
    },
    handleSortField (e) {
      if (e.children) {
        this.toSortData = e.children
      } else {
        this.toSortData = this.detailData
      }
      this.isSort = true
    },
    handleSortNode (sort) {
      const sortData = sort.join(',')
      sortField(sortData).then((res) => {
        this.$message.success('排序成功')
        this.handlePageChange()
      })
    },
    selectChange () {
      this.detailData.forEach((item) => {
        if (item.fieldName === this.searchData) {
          this.detailData = [item]
        }
      })
    },
    handleGetUsers (e) {
      this.toUserData = e.id
      this.isUsers = true
    },
    init () {
      this.loading = true
      getRelationDetail(this.ralationId)
        .then((res) => {
          this.detailData = res
        })
        .finally((this.loading = false))
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters(['userInfo']),
    userRight: function () {
      return this.userInfo.id === 1
    }
  },
  components: {
    EditFeild,
    Sort,
    Users,
    Merger
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-drawer.rtl {
  border-radius: 5px;
}
/deep/.el-table__expanded-cell[class*="cell"] {
  padding: 0;
}
/deep/.el-table__body-wrapper /deep/ .el-table__header-wrapper {
  display: none;
}
.detail-header {
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: space-between;
}
.feild-color {
  display: inline-block;
  width: 70px;
  height: 23px;
  line-height: 23px;
  text-align: center;
}
</style>
