<template>
  <el-dialog
    :title="`${fieldData ? '编辑' : '新增'}字段组`"
    :visible.sync="show"
    width="550px"
    :before-close="handleClose"
  >
    <el-form label-width="120px">
      <el-form-item label="数据类型">
        <el-input v-model="form.dataType" placeholder="请输入数据类型" />
      </el-form-item>
      <el-form-item label="类型名称">
        <el-input v-model="form.typeName" placeholder="请输入类型名称" />
      </el-form-item>
      <el-form-item label="图片地址">
        <input
          type="file"
          ref="file"
          @change="fileChange"
          name="file"
          accept="image/*"
          class=""
        />
        <span class="replace-inputFileStyle">点击上传图片</span>
        <el-input
          v-model="form.img"
          placeholder="请输入图片地址"
          v-show="false"
        />
      </el-form-item>
      <el-form-item label="图片">
        <img v-if="form.img !== ''" class="upload-img" :src="form.img" alt="" />
      </el-form-item>
      <el-form-item label="是否为图形统计">
        <el-switch v-model="switchStatu" @change="handleSwitch()"> </el-switch>
      </el-form-item>
      <!-- <el-form-item label="字段颜色">
        <el-color-picker v-model="form.fontColor" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>
      <el-form-item label="标题大小">
        <el-input v-model="form.fontSize" placeholder="请输入标题大小" />
      </el-form-item>
      <el-form-item label="字段宽度">
        <el-input v-model="form.width" placeholder="请输入字段宽度" />
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addFieldGroup, changeFieldGroup } from '@/api/field.js'
import { uploadFile } from '@/api/common'
export default {
  name: 'edit',
  props: {
    show: Boolean,
    fieldData: [Object, Array]
  },
  watch: {
    show: function () {
      if (this.fieldData) {
        this.switchStatu = this.fieldData.graph === 1
        this.form = {
          dataType: this.fieldData.dataType,
          typeName: this.fieldData.typeName,
          img: this.fieldData.img,
          widthScale: this.fieldData.widthScale,
          heightScale: this.fieldData.heightScale
        }
      } else {
        this.form = {
          dataType: '',
          typeName: '',
          img: '',
          widthScale: 12,
          heightScale: 318,
          switchStatu: false
        }
        // this.form.widthScale = 12;
        // this.form.heightScale = 318;
        // this.form.fontSize = 12;
        // this.form.fontColor = "#272727";
        // // this.form.width = 312;
        // this.switchStatu = false;
      }
    }
  },
  data () {
    return {
      form: {},
      switchStatu: false,
      optionsWdith: [
        {
          value: 6,
          label: '四分之一'
        },
        {
          value: 12,
          label: '二分之一'
        },
        {
          value: 18,
          label: '四分之三'
        },
        {
          value: 24,
          label: '全屏'
        }
      ],
      optionsHeight: [
        {
          // value: 767,
          value: 726,
          label: '全屏'
        },
        {
          // value: 312
          value: 318,
          label: '二分之一'
        },
        {
          value: 182,
          label: '三分之一'
        }
      ],
      predefineColors: [
        '#272727',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      uploadImg: ''
    }
  },
  computed: {
    switchChange: function () {
      return this.switchStatu ? 1 : 0
    }
  },
  methods: {
    handleSubmit () {
      this.form.graph = this.switchChange
      if (this.checkForm() === true) {
        if (this.fieldData) {
          changeFieldGroup(this.fieldData.id, {
            dataType: this.form.dataType,
            typeName: this.form.typeName,
            img: this.form.img,
            graph: this.form.graph,
            widthScale: this.form.widthScale,
            heightScale: this.form.heightScale
            // fontSize: this.fieldData.fontSize,
            // fontColor: this.fieldData.fontColor,
            // width: this.fieldData.width,
          }).then((res) => {
            this.$message.success('保存成功')
            this.handleClose()
            this.$emit('refresh')
          })
        } else {
          addFieldGroup({
            dataType: this.form.dataType,
            typeName: this.form.typeName,
            img: this.form.img,
            graph: this.form.graph,
            widthScale: this.form.widthScale,
            heightScale: this.form.heightScale
          }).then((res) => {
            this.$message.success('保存成功')
            this.handleClose()
            this.$emit('refresh')
          })
        }
      } else {
        this.$message.warning('请输入' + this.checkForm())
      }
    },
    handleClose () {
      this.form = {}
      this.switchStatu = false
      this.$emit('close')
    },
    handleSwitch () {
      this.form.graph = this.switchChange
    },
    checkForm () {
      if (!this.form.dataType) {
        return '数据类型'
      } else if (!this.form.typeName) {
        return '类型名称'
      } else if (!this.form.img) {
        return '图片地址'
      }
      return true
    },
    fileChange (e) {
      // const file = e.srcElement.files[0]
      // !修改上传方式 支持多张图片上传
      const fileList = e.srcElement.files
      this.files = e.srcElement.files
      const fileLength = fileList.length
      if (fileLength > 6) {
        this.$message({
          showClose: true,
          message: '单次最多上传6张图片!',
          type: 'warning'
        })
        return
      }
      // this.testPreView()
      for (let i = 0; i < fileLength; i++) {
        const file = fileList[i]
        if (file) {
          if (file.size > 1024 * 1024 * 2) {
            this.$message.warning('图片大小不能超过2M，请重新选择图片')
            return false
          }
          this.loading = true
          uploadFile({ file })
            .then((res) => {
              this.uploadImg = res
              this.form.img = res
            })
            .finally(() => {
              this.$refs.file.value = null
              this.loading = false
            })
        }
      }
    }
  },
  created () {}
}
</script>

<style scoped>
.tips {
  font-size: 12px;
  color: #999;
}
input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 104px;
  height: 40px;
}
.replace-inputFileStyle {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  background: #1c9399;
  border-radius: 4px;
  padding: 0 10px;
}
.upload-img {
  width: 300px;
  height: 150px;
}
</style>
