<template>
  <el-drawer
    title="所有用户"
    :visible.sync="show"
    width="550px"
    append-to-body
    :before-close="handleClose"
  >
    <div class="all-check">
      <el-checkbox v-model="isAllCheck" @change="handleAllCheck"
        >全选</el-checkbox
      >
    </div>
    <el-checkbox-group
      v-model="checkList"
      style="padding: 10px; max-height: 80vh; overflow-y: auto"
    >
      <el-checkbox
        :label="item.name"
        v-for="item in allUser"
        :key="item.id"
        style="width: 70px; margin: 0 10px"
      ></el-checkbox>
    </el-checkbox-group>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">保 存</el-button>
    </div>
  </el-drawer>
</template>

<script>
import {
  getFieldUser,
  getAllUsersField,
  changeFieldUser
} from '@/api/field.js'
export default {
  name: 'edit',
  props: {
    show: Boolean,
    fieldData: [Object, Array],
    fieldId: Number
  },
  watch: {
    show: function () {},
    fieldId: function () {
      this.getusers()
    },
    checkList: function () {
      if (this.checkList.length === this.allUser.length) {
        this.isAllCheck = true
      } else {
        this.isAllCheck = false
      }
    }
    // isAllCheck: function (nv) {
    //   if (nv) {
    //     this.checkList = [];
    //     for (let i = 0; i < this.allUser.length; i++) {
    //       this.checkList.push(this.allUser[i].name);
    //     }
    //   } else {
    //     this.checkList = [];
    //   }
    // },
  },
  data () {
    return {
      checkList: [],
      allUser: null,
      fieldUser: null,
      fieldUserId: null,
      sendList: [],
      isAllCheck: false
    }
  },
  methods: {
    handleSubmit () {
      this.checkSendList()
      if (this.sendList.length === 0) {
        changeFieldUser(this.fieldId, []).then((res) => {
          this.$message.success('保存成功')
          this.handleClose()
          this.$emit('refresh')
        })
      } else {
        changeFieldUser(this.fieldId, this.sendList).then((res) => {
          this.$message.success('保存成功')
          this.handleClose()
          this.$emit('refresh')
        })
      }
    },
    getusers () {
      getFieldUser(this.fieldId).then((res) => {
        this.fieldUser = res
        for (let i = 0; i < this.allUser.length; i++) {
          for (let j = 0; j < this.fieldUser.length; j++) {
            if (this.allUser[i].id === this.fieldUser[j]) {
              this.checkList.push(this.allUser[i].name)
            }
          }
        }
      })
    },
    handleClose () {
      this.sendList = []
      this.$emit('close')
    },
    checkSendList () {
      for (let i = 0; i < this.allUser.length; i++) {
        for (let j = 0; j < this.checkList.length; j++) {
          if (this.allUser[i].name === this.checkList[j]) {
            this.sendList.push(this.allUser[i].id)
          }
        }
      }
    },
    handleAllCheck (e) {
      if (e) {
        this.checkList = []
        for (let i = 0; i < this.allUser.length; i++) {
          this.checkList.push(this.allUser[i].name)
        }
      } else {
        this.checkList = []
      }
    }
  },
  created () {
    getAllUsersField({ role: 1 }).then((res) => {
      this.allUser = res
      this.fieldUserId = this.allUser.map((item) => {
        return item.id
      })
    })
  }
}
</script>

<style scoped>
.dialog-footer {
  margin-top: 30px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
}
.all-check {
  padding: 10px 80px;
  display: flex;
  justify-content: flex-end;
}
</style>
